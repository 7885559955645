 <!-- Nav tabs -->
    <nav class="navbar navbar-expand-sm navbar-light">
        <a class="navbar-brand" href="/">The Soda Factory</a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavId">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                <li class="nav-item active">
                    <a class="nav-link" href="https://www.sodafactory.io/" target="_blank">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.sodafactory.io/home/#pass" target="_blank">Membership</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.sodafactory.io/home/#artists" target="_blank">Artists</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.sodafactory.io/home/#collaborate" target="_blank">Collaborate</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.sodafactory.io/home/#people" target="_blank">People</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="https://discord.gg/eeybmfvdTh" target="_blank"><i class="bi bi-discord"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://twitter.com/Soda_Factory_" target="_blank"><i class="bi bi-twitter"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://www.instagram.com/sodafactory.io" target="_blank"><i class="bi bi-instagram"></i></a>
                </li>
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <button class="btn btn-outline-success wallet-btn my-2 my-sm-0" type="button" (click)="account === '' ? clickButton() : disconnectMetamask()">{{ account !== '' ? 'Disconnect' : 'Connect Wallet' }}</button>
            </form>
        </div>
    </nav>


    <main>
        <div class="container">
            <div class="row">
                <div class="col-md-12 p-4 text-center">
                    <h3 class="mint-title">Mr. Everybody Possessions Collection</h3>
                </div>
                <div class="col-md-12 text-center">
                    <p>Minting Opens:</p>
                    <p>Dec 23rd 7PM UTC for Soda Pass & Mr Everybody holders.</p>
                    <!-- <p>Price 0.06ETH</p> -->
                    <!-- <p>Dec 25th 10AM UTC for public sale.</p> -->
                    <!-- <p>Price 0s.07ETH</p> -->
                </div>
                <div class="col-md-12 text-center">
                    <button class="btn plus-btn" (click)="changeNo('plus')"><i class="bi bi-plus-lg"></i></button>
                    <p class="token-count">{{ selectedToken }}</p>
                    <button class="btn"  (click)="changeNo('minus')"><i class="bi bi-dash-lg"></i></button>
                </div>
                <div class="col-md-12 mt-3 text-center">
                    <button [disabled]="!isSaleStarted" class="btn btn-mint" (click)="mintToken()">Mint{{ selectedToken }}</button>
                    <h1 class="remain-title">{{ totalMintedToken }}/1636 minted</h1>
                </div>
            </div>
        </div>
        <div class="container footer">
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <p class="col-md-4 mb-0">info@sodafactory.io</p>

                <a  class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    The Soda Factory
                </a>

                <ul class="nav col-md-4 justify-content-end">
                    <li class="nav-item"><a href="https://discord.gg/eeybmfvdTh" target="_blank" class="px-2">Discord</a></li>
                    <li class="nav-item"><a href="https://twitter.com/Soda_Factory_" target="_blank" class="px-2">Twitter</a></li>
                </ul>
            </footer>
        </div>
    </main>
